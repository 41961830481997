import React, { useState } from "react";

// seo
import Seo from "../components/Seo";

// constants
import constants from "../constants";

// components
import ToggleButton from "../components/WbdtPlans/ToggleButton/ToggleButton";
import ProgramIncludes from "../components/WbdtPlans/ProgramIncludes/ProgramIncludes";
import PlanCards from "../components/WbdtPlans/PlanCards/PlanCards";
import NewFooter from "../components/Footer/NewFooter";

const WbdtPlanPricingPage = () => {
  const [selectedTreatmentData, setSelectedTreatmentData] = useState(
    constants.WBDT_TREATMENT_PLANS[0]
  );

  const handleToggleButton = value => {
    if (value) {
      const selectedData = constants.WBDT_TREATMENT_PLANS.find(
        data => data.treatmentTypeValue === value
      );
      setSelectedTreatmentData(selectedData);
    }
  };
  return (
    <div>
      <Seo
        title="Reverse Diabetes, Obesity, PCOS & More"
        description="AI-driven treatment to reverse Diabetes, Hypertension, Obesity & Other Chronic Conditions. 97% reduced blood sugar & meds, 98% eliminated symptoms. Start today."
      />

      <ToggleButton
        treatmentPlans={constants.WBDT_TREATMENT_PLANS}
        handleToggleButton={handleToggleButton}
      />
      <ProgramIncludes />
      <PlanCards selectedTreatmentData={selectedTreatmentData} />
      <NewFooter bgColor="#FFFFFF" textColor="#2F4079" />
    </div>
  );
};

export default WbdtPlanPricingPage;
